:root {
    --typography-header-3xl-bold: 700 96px/112px Roobert;
    --typography-header-2xl-bold: 700 48px/52px Roobert;
    --typography-header-xl-bold: 700 40px/44px Roobert;
    --typography-header-l-semibold: 600 28px/36px Roobert;
    --typography-header-m-semibold: 600 24px/32px Roobert;
    --typography-header-s-semibold: 600 20px/28px Roobert;
    --typography-header-xs-semibold: 600 17px/24px Roobert;

    --typography-body-l-semibold: 600 24px/32px Roobert;
    --typography-body-l-medium: 500 24px/32px Roobert;
    --typography-body-l-regular: 400 24px/32px Roobert;
    --typography-body-ml-semibold: 600 20px/28px Roobert;
    --typography-body-ml-medium: 500 20px/28px Roobert;
    --typography-body-ml-regular: 400 20px/28px Roobert;
    --typography-body-m-semibold: 600 17px/24px Roobert;
    --typography-body-m-medium: 500 17px/24px Roobert;
    --typography-body-m-regular: 400 17px/24px Roobert;
    --typography-body-s-semibold: 600 15px/20px Roobert;
    --typography-body-s-medium: 500 15px/20px Roobert;
    --typography-body-s-regular: 400 15px/20px Roobert;
    --typography-body-xs-semibold: 600 13px/16px Roobert;
    --typography-body-xs-medium: 500 13px/16px Roobert;
    --typography-body-xs-regular: 400 13px/16px Roobert;
    --typography-body-2xs-semibold: 600 11px/12px Roobert;
    --typography-body-2xs-medium: 500 11px/12px Roobert;
    --typography-body-2xs-regular: 400 11px/12px Roobert;

    --typography-link-l-medium: 500 24px/32px Roobert;
    --typography-link-ml-medium: 500 20px/28px Roobert;
    --typography-link-m-medium: 500 17px/24px Roobert;
    --typography-link-s-medium: 500 15px/20px Roobert;
    --typography-link-xs-medium: 500 13px/16px Roobert;
    --typography-link-2xs-medium: 500 11px/12px Roobert;
}

html[dir="rtl"] {
    --typography-header-3xl-bold: 700 96px/112px system-ui;
    --typography-header-2xl-bold: 700 48px/52px system-ui;
    --typography-header-xl-bold: 700 40px/44px system-ui;
    --typography-header-l-semibold: 600 28px/36px system-ui;
    --typography-header-m-semibold: 600 24px/32px system-ui;
    --typography-header-s-semibold: 600 20px/28px system-ui;
    --typography-header-xs-semibold: 600 17px/24px system-ui;

    --typography-body-l-semibold: 600 24px/32px system-ui;
    --typography-body-l-medium: 500 24px/32px system-ui;
    --typography-body-l-regular: 400 24px/32px system-ui;
    --typography-body-ml-semibold: 600 20px/28px system-ui;
    --typography-body-ml-medium: 500 20px/28px system-ui;
    --typography-body-ml-regular: 400 20px/28px system-ui;
    --typography-body-m-semibold: 600 17px/24px system-ui;
    --typography-body-m-medium: 500 17px/24px system-ui;
    --typography-body-m-regular: 400 17px/24px system-ui;
    --typography-body-s-semibold: 600 15px/20px system-ui;
    --typography-body-s-medium: 500 15px/20px system-ui;
    --typography-body-s-regular: 400 15px/20px system-ui;
    --typography-body-xs-semibold: 600 13px/16px system-ui;
    --typography-body-xs-medium: 500 13px/16px system-ui;
    --typography-body-xs-regular: 400 13px/16px system-ui;
    --typography-body-2xs-semibold: 600 11px/12px system-ui;
    --typography-body-2xs-medium: 500 11px/12px system-ui;
    --typography-body-2xs-regular: 400 11px/12px system-ui;

    --typography-link-l-medium: 500 24px/32px system-ui;
    --typography-link-ml-medium: 500 20px/28px system-ui;
    --typography-link-m-medium: 500 17px/24px system-ui;
    --typography-link-s-medium: 500 15px/20px system-ui;
    --typography-link-xs-medium: 500 13px/16px system-ui;
    --typography-link-2xs-medium: 500 11px/12px system-ui;
}

select,
input,
textarea {
    background-color: #ffffff;
    font-family: system-ui;
}

select:disabled,
input:disabled,
textarea:disabled {
    cursor: not-allowed;
}

select:disabled::selection,
input:disabled::selection,
textarea:disabled::selection {
    background: transparent; /* Remove selection background color */
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

select {
    -webkit-appearance: none;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}


.scrollBar0::-webkit-scrollbar {
    width: 0px;
    height: 0px;
}

.scrollBar1::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

.scrollBar1::-webkit-scrollbar-track {
    border-radius: 10px;
}

.scrollBar1::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(64, 71, 83, 0.8);
}

.scrollBar1::-webkit-scrollbar-thumb:hover {
    background: rgba(64, 71, 83, 1);
}

/* BTN Hover Effect */
.btn_effect {
    position: relative;
    z-index: 1;
}

.btn_effect::after {
    content: '';
    width: 60%;
    height: 60%;
    background-color: transparent;
    border-radius: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
    transition: all 0.2s ease-in;
}

.btn_effect:hover::after {
    background-color: #eee;
    width: 100%;
    height: 100%;
}

/* Animations */
.animation-01 {
    animation-name: animation01;
    animation-duration: .3s;
}

@keyframes animation01 {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.animation-02 {
    animation-name: animation02;
    animation-duration: .3s;
}

@keyframes animation02 {
    from {
        transform: translateY(10px);
    }

    to {
        transform: translateY(0px);
    }
}

.animation-03 {
    animation-name: animation03;
    animation-duration: .3s;
}

@keyframes animation03 {
    from {
        transform: scale(0.9);
    }

    to {
        transform: scale(1);
    }
}

.animation-04 {
    animation-name: animation04;
    animation-duration: .3s;
}

@keyframes animation04 {
    from {
        transform: translateX(10px);
    }

    to {
        transform: translateX(0px);
    }
}

/* alerts-animation */
.alerts-animation-1 {
    animation-name: alertsAnimation1;
    animation-duration: .3s;
}

@keyframes alertsAnimation1 {
    from {
        top: -10rem;
    }

    to {
        top: 2rem;
    }
}

.alerts-animation-2 {
    animation-name: alertsAnimation2;
    animation-duration: .3s;
}

@keyframes alertsAnimation2 {
    from {
        bottom: -10rem;
    }

    to {
        bottom: 2rem;
    }
}